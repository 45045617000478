var formManager = (function () {
    var isAjaxSubmitRequest;
    var form;
    var success;
    var error;
    var url = ENV.formSubmitUrl;
    var citiesJSON = ENV.appRoot+'cities/';
    var officesJSON = ENV.appRoot+'offices/';

    function init() {
        form = $(".contactForm");
        success = $(".contactFormSuccess");
        error = $(".contactFormError");
        isAjaxSubmitRequest = false;
        if (form.length) {
            initRequired();
            eventHandler();
        }
    }

    function doAjaxRequestForFillCities(country) {
        var mrk = "";
        showLoader();
        $.ajax({
            type: "GET",
            url: citiesJSON+country.toLocaleString(),
            dataType: "json",
            success: function(response) {
                hideLoader();
                $.each(response.cities, function(i,city) {
                    mrk += "<option value='" + city + "'>" + city + "</option>";
                });
                $('#city option')
                    .filter(function() {
                        return this.value;
                    })
                    .remove();
                $("#city").append(mrk).focus().click();
            },
            error: function() {
                hideLoader();
            }
        });
    }

    function doAjaxRequestForFillOffices(country,city) {
        var mrk = "";
        showLoader();
        $.ajax({
            type: "GET",
            url: officesJSON+country.toLowerCase()+'/'+city.toLowerCase(),
            dataType: "json",
            success: function(response) {
                hideLoader();
                $.each(response.offices, function(i,office) {
                    mrk += "<option value='" + office.id + "'>" + office.name + "</option>";
                });
                $('#office option')
                    .filter(function() {
                        return this.value;
                    })
                    .remove();
                $("#office").append(mrk).focus().click();
            },
            error: function() {
                hideLoader();
            }
        });
    }

    function initRequired() {
        $('.form-control:required').each(function () {
            var formGroup = $(this).closest('.form-group');
            if (typeof formGroup !== typeof undefined) {
                formGroup.addClass('required');
            }
        });
    }

    function isValidForm() {
        resetFormError();
        var isValid = true;
        var firstname = $("#contactorFirstname", form);
        var lastname = $("#contactorLastname", form);
        var email = $("#contactorEmail", form);
        var country = $("#country", form);
        var city = $("#city", form);
        var office = $("#office", form);
        var message = $("#contactorMessage", form);
        var privacy = $("#privacyPolicy", form);

        if (firstname.val() === '') {
            isValid = false;
            showFormError(firstname);
        }
        if (lastname.val() === '') {
            isValid = false;
            showFormError(lastname);
        }
        if (email.val() === '') {
            isValid = false;
            showFormError(email);
        }
        if (country.val() === '' || country.val() === null) {
            isValid = false;
            showFormError(country);
        }
        if (city.val() === '' || city.val() === null) {
            isValid = false;
            showFormError(city);
        }
        if (office.val() === '' || office.val() === null) {
            isValid = false;
            showFormError(office);
        }
        if (message.val().length > 255) {
            isValid = false;
            showFormError(message);
        }
        if (!privacy.is(':checked')) {
            isValid = false;
            showFormError(privacy);
        }

        return isValid;
    }

    function getFormObject() {
        try {
            if (typeof form === typeof undefined) return "";
            var formObj = {};
            var elem = $('input.form-control, select.form-control, textarea, input.form-check-input, input[type="hidden"]',form);
            elem.each(function () {
                var item = $(this);
                var key = item.attr('name');
                var value = item.val();

                if (item.is(':radio') && item.is(':checked') && typeof key !== typeof undefined) {
                    formObj[key] = value;
                }
                if (item.is('textarea') && value !== '' && value !== null && typeof key !== typeof undefined) {
                    formObj[key] = value;
                }
                if (item.is(':checkbox') && item.is(':checked') && typeof key !== typeof undefined) {
                    formObj[key] = value;
                }
                if (value !== '' && value !== null && item.is('select') && typeof key !== typeof undefined) {
                    formObj[key] = value;
                }
                if (value !== '' && value !== null && item.not(':checkbox,:radio').is('input') && typeof key !== typeof undefined) {
                    formObj[key] = value;
                }
            });
            return formObj;
        } catch (e) {
            console.log(e)
        }
    }

    function getMultiPartFormData() {
        var attachment = $(".attachment", form);
        var contactType = $("#contactType", form);
        var formData = new FormData();

        formData.append(contactType.attr('name'),contactType.val());

        var datas = getFormObject();
        for ( var data in datas ) {
            formData.append(data, datas[data]);
        }

        if (typeof (attachment[0].files) !== typeof undefined && attachment[0].files.length) {
            formData.append(attachment[0].name, attachment[0].files[0]);
        }
        return formData;
    }

    function manageFormCollapse() {
        var formLink1 = $(".formBox__moreinfo__link.work");
        var boxLink1 = formLink1.closest('.formBox');
        var formLink2 = $(".formBox__moreinfo__link.study");
        var boxLink2 = formLink2.closest('.formBox');
        var isOpen = false;
        $(formLink1).on("click", function (e) {
            $(".formBox").removeClass('open');
            var link = $(this);
            var ref = link.attr("href");
            var dataForm = link.attr("data-form");
            var box = link.closest('.formBox');

            box.addClass('open');

            $(boxLink2).addClass('disabled');

            if (box.hasClass('formBox--green')) {
                $(ref).removeClass('formBoxCollapse--blue').addClass('formBoxCollapse--green');
            } else {
                $(ref).removeClass('formBoxCollapse--green').addClass('formBoxCollapse--blue');
            }

            $(ref).collapse('toggle');

            $("[data-form-title]").addClass('d-none');
            $("[data-form-title="+dataForm+"]").removeClass('d-none');
            $("#contactType", form).val(dataForm);

            $(ref).on("hide.bs.collapse",function () {
                $("[data-form-title="+dataForm+"]").addClass('d-none');
                box.removeClass('open');
                $(boxLink2).removeClass('disabled');
                $("#contactType", form).val('');
            });

            if (box.length && !$(ref).hasClass('show')){
                $('html, body').animate({
                    scrollTop: box.offset().top
                }, 500);
            }
            e.preventDefault();
        });

        $(formLink2).on("click", function (e) {
            $(".formBox").removeClass('open');
            var link = $(this);
            var ref = link.attr("href");
            var dataForm = link.attr("data-form");
            var box = link.closest('.formBox');

            box.addClass('open');
            $(boxLink1).addClass('disabled');

            if (box.hasClass('formBox--green')) {
                $(ref).removeClass('formBoxCollapse--blue').addClass('formBoxCollapse--green');
            } else {
                $(ref).removeClass('formBoxCollapse--green').addClass('formBoxCollapse--blue');
            }

            $(ref).collapse('toggle');

            $("[data-form-title]").addClass('d-none');
            $("[data-form-title="+dataForm+"]").removeClass('d-none');
            $("#contactType", form).val(dataForm);

            $(ref).on("hide.bs.collapse",function () {
                $("[data-form-title="+dataForm+"]").addClass('d-none');
                box.removeClass('open');
                $(boxLink1).removeClass('disabled');
                $("#contactType", form).val('');
            });

            if (box.length && !$(ref).hasClass('show')){
                $('html, body').animate({
                    scrollTop: box.offset().top
                }, 500);
            }
            e.preventDefault();
        });
    }

    function eventHandler() {
        manageFormCollapse();

        $(".resetContactForm").on("click", function () {
            resetForm();
        });

        $("button[type='submit']", form).on("click", function (e) {
            e.preventDefault();
            if (isValidForm()) {
                doAjaxRequestForSubmitForm();
            }
           return false;
        });

        $(document).on("change",".attachment",function () {
            validateAttachment($(this));
        });

        $("#country").on("change", function(e) {
            var val = $(this).val();
            if (val !== '') {
                doAjaxRequestForFillCities(val)
            }
            e.preventDefault();
        });

        $("#city").on("change", function (e) {
            var city = $(this).val();
            var country = $("#country").val();
            if (city !== '' && country !== '') {
                doAjaxRequestForFillOffices(country, city);
            }
            e.preventDefault();
        })
    }

    function doAjaxRequestForSubmitForm() {
        if (isAjaxSubmitRequest) {
            return;
        } else {
            isAjaxSubmitRequest = true;
            disableForm();
            $.ajax({
                type: "POST",
                url: url,
                data: getMultiPartFormData(),
                dataType: "json",
                cache: false,
                /**
                 * Add processData and ContentType if you have form enctype="multipart/form-data"
                 */
                processData: false,  // tell jQuery not to process the data
                contentType: false,  // tell jQuery not to set contentType,
                success: function(response) {
                    isAjaxSubmitRequest = false;
                    showSuccessMessage();
                    if(grecaptcha) {
                        grecaptcha.reset();
                    }

                },
                error: function(response) {
                    isAjaxSubmitRequest = false;
                    showErrorMessage();
                }
            });
        }
    }

    function showFormError(target) {
        var message = target.attr("data-validation-message");
        var inValidFeedbackTemplate = '';
        if (typeof message !== typeof undefined) {
            inValidFeedbackTemplate = '<div class="invalid-feedback">'+message+'</div>';
        } else {
            inValidFeedbackTemplate = '<div class="invalid-feedback">Field Required</div>';
        }
        var formGroup;
        if (target.is('select')) {
            formGroup = target.closest('.form-group__select');
        } else {
            formGroup = target.closest('.form-group');
        }
        formGroup.find('.invalid-feedback').remove();
        target.removeClass('is-invalid');
        formGroup.append(inValidFeedbackTemplate);
        target.addClass('is-invalid');
    }

    function removeFormError(elem) {
        var formControl = $(".form-control, .form-check-input", elem);
        formControl.each(function () {
            var target = $(this);
            var formGroup = target.closest('.form-group');
            formGroup.find('.invalid-feedback').remove();
            target.removeClass('is-invalid');
        });
    }

    function resetFormError() {
        var formControl = $(".form-control, .form-check-input", form);
        formControl.each(function () {
            var target = $(this);
            var formGroup = target.closest('.form-group');
            formGroup.find('.invalid-feedback').remove();
            target.removeClass('is-invalid');
        });
    }

    function disableForm() {
        $(".form-control", form).prop('disabled', true);
        $(".form-check-input", form).prop('disabled', true);
        $(".sentContactForm", form).addClass('disabled');
    }

    function enableForm() {
        $(".form-control", form).prop('disabled', false);
        $(".form-check-input", form).prop('disabled', false);
        $(".sentContactForm", form).removeClass('disabled');
    }

    function hideContactForm() {
        form.addClass('d-none');
    }

    function showContactForm() {
        form.removeClass('d-none');
    }

    function showSuccessMessage() {
        hideContactForm();
        hideErrorMessage();
        success.removeClass('d-none');
    }

    function hideSuccessMessage() {
        success.addClass('d-none');
    }

    function showErrorMessage() {
        hideContactForm();
        hideSuccessMessage();
        error.removeClass('d-none');
    }

    function hideErrorMessage() {
        error.addClass('d-none');
    }
    
    function resetForm(form) {
        $('.form-control', form).not('.input-checkbox').val('');
        $('select', form).each(function () {
            this.selectedIndex = 0;
        });
        $(".form-check-input", form).prop("checked", false);
        hideSuccessMessage();
        hideErrorMessage();
        enableForm();
        showContactForm();
    }
    
    function showLoader() {
        $(".loader", form).removeClass('d-none');
    }
    
    function hideLoader() {
        $(".loader", form).addClass('d-none');
    }

    function validateAttachment(target) {
        var fileInput = target;
        var ext = null;
        var allowed = null;
        var filename = null;
        var size = 0;
        var ATTACHMENTS_MAX_SIZE = parseFloat(fileInput.attr("data-allowed"));
        ext = fileInput.val().match(/\.([^.]*)$/)[1];
        filename = fileInput.val().match(/([^\/\\]+)$/)[1];
        allowed = fileInput.attr("data-allowed").split(",");
        if ($.isEmptyObject(allowed) || fileInput.attr("data-allowed") === '') {
            showFormError(fileInput);
            return false;
        }
        removeFormError(fileInput);
        if (typeof (fileInput[0].files) !== typeof undefined) {
            size = parseFloat(fileInput[0].files[0].size / 1024);
            if (allowed.indexOf(ext) === -1) {
                fileInput.val("")
                    .after('')
                    .removeClass('hasAttachment');
                showFormError(fileInput);
                return;
            }
            if (size > ATTACHMENTS_MAX_SIZE) {
                fileInput.val("")
                    .after('')
                    .removeClass('hasAttachment');
                showFormError(fileInput);
                return;
            }
            fileInput.attr('disabled', false)
                .after('<span>'+filename+'</span>')
                .addClass('hasAttachment');
            removeFormError(fileInput);
        } else {
            console.log("Validazione dimensione immagine non supportato dal browser.");
        }
    }

    return {
        init: init
    };
})(jQuery);
