var news = (function () {
    function init() {
        if ($(".news").length) {
            setImageCoverMinHeight();
            $(window).on("resize", function () {
                setImageCoverMinHeight();
            });
        }
    }

    function setImageCoverMinHeight() {
        var heightToSet = $(".featureBox__slider").height();
        $(".featureBox__slider__image--cover").each(function () {
            var imgCover = $(this);
            imgCover.css('min-height', heightToSet);
        })
    }

    return {
        init: init
    };
})(jQuery);