var featureBox = (function () {
    function init() {
        initFeatureBoxSlider();
        $(window).on("resize", function () {
            setImageCoverMinHeight();
        });
    }

    function initFeatureBoxSlider() {
        var slider = $('.featureBox__slider');
        var sliderCount = slider.length;
        if (sliderCount === 1) {
            var option = getSliderOptionBasedNumberOfElements(slider);
            slider.owlCarousel(option);
            setImageCoverMinHeight();
        } else if (sliderCount > 1) {
            slider.each(function () {
               $(this).owlCarousel(
                   getSliderOptionBasedNumberOfElements(this)
               );
            });
            setImageCoverMinHeight();
        }
    }

    function setImageCoverMinHeight() {
        $(".featureBoxSection__single").each(function () {
            var imgCover = $(".featureBox__slider__image--cover", this);
            var wrapper = $(".featureBox__wrapper--cover", this);
            if (imgCover.length) {
                if (imgCover.length > 1) {
                    imgCover.each(function () {
                        $(this).css('min-height', wrapper.outerHeight());
                    })
                } else if(imgCover === 1) {
                    imgCover.css('min-height', wrapper.outerHeight());
                }
            }
        })
    }

    function getSliderOptionBasedNumberOfElements(slider) {
        var countItem = $(".item", slider).length;
        var option = {
            items: 1,
            margin: 0,
            singleItem: true,
            autoHeight: true
        };

        if (countItem === 1) {
            option.loop = false;
            option.mouseDrag = false;
        } else {
            option.loop = true;
            option.autoplayTimeout = 3000;
            option.autoplayHoverPause = true;
            option.autoplay = true;
            option.smartSpeed = 450;
        }
        return option;
    }

    return {
        init: init
    };
})(jQuery);
