var collapseBox = (function () {
    var isOpen = false;
    function init() {
        toggleCollapseBoxClass();
    }

    function toggleCollapseBoxClass() {
        $(".boxText p.text").on("click", function (e) {
            $(".boxText p.text").not(this).removeClass('open');
           $(this).toggleClass('open');
           e.preventDefault();
        });
        var link = null;
        var ref = null;
        var box = null;
        $('.collapseBox__moreinfo__link, .moreInfoBtn__link').on("click", function (e) {
            var link = $(this);
            var ref = link.attr("href");
            var box = link.closest('.collapseBox, .featureBox');
            restoreCollapseBox(ref);
            collapseToggle(ref,box);

            $(ref).not('.toggleDetail').on('shown.bs.collapse', function () {
                isOpen = true;
            });
            if (box.length && !isOpen){
                $('html, body').animate({
                    scrollTop: box.offset().top
                }, 500);
            }

            e.preventDefault();
        });
    }

    function collapseToggle(ref, box) {
        //$(ref).collapse('show');
        $(ref).on("show.bs.collapse",function () {
            box.addClass('open');
        });
        $(ref).on("hide.bs.collapse",function () {
            box.removeClass('open');
            isOpen = false;
        });
    }

    function restoreCollapseBox(ref) {
        $('.collapseBox, .formBox, .featureBox').removeClass('open');
        $(".collapse").not(ref).collapse('hide');
    }

    return {
        init: init
    };
})(jQuery);
