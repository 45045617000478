var officeManager = (function () {
    var CONF = {
        "base_url": ENV.appRoot,
        "lang":"it",
        "params":ENV.params,
        "assets":"/assets/",
        "page_url": ENV.page_url,
        "mapsJSON": ENV.mapsJSON
    };
    var DATAMAP_URL = CONF.mapsJSON;
    var citiesJSON = ENV.appRoot+'cities/';
    function init() {
        if ($("#theMap").length && DATAMAP_URL !== '') {
            sedi.init();
        }
    }

    // Maps
    var markerStylesGreen = [{
        url: ENV.assets + "img/shared/pin_v.png",
        height: 67,
        width: 53,
        anchor: [15, 23],
        textColor: '#003970',
        textSize: 11
    }, {
        url: CONF.assets + "img/shared/pin_v.png",
        height: 67,
        width: 53,
        anchor: [15, 19],
        textColor: '#003970',
        textSize: 11
    }, {
        url: CONF.assets + "img/shared/pin_v.png",
        height: 67,
        width: 53,
        anchor: [15, 15],
        textColor: '#003970',
        textSize: 11
    }];
//tutti verdi
    var markerStylesBlue = markerStylesGreen;
    /*var markerStylesBlue = [{
        url: CONF.assets + "img/shared/pin_b.png",
        height: 67,
        width: 53,
        anchor: [15, 23],
        textColor: '#003970',
        textSize: 11
    }, {
        url: CONF.assets + "img/shared/pin_b.png",
        height: 67,
        width: 53,
        anchor: [15, 19],
        textColor: '#003970',
        textSize: 11
    }, {
        url: CONF.assets + "img/shared/pin_b.png",
        height: 67,
        width: 53,
        anchor: [15, 15],
        textColor: '#003970',
        textSize: 11
    }];*/


    var mapStyles = [
        { "featureType": "poi", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "road", "stylers": [ { "visibility": "on" } ] }
        , { "featureType": "road.highway", "elementType": "geometry", "stylers": [ { "color": "#5d84bf" } ] }
        , { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [ { "color": "#ffffff", "weight":0.1 } ] }
        , { "featureType": "road.highway", "elementType": "labels.text.stroke", "stylers": [ { "color": "#5d84bf" } ] }
        , { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#6479a9" } ] }
        , { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [ { "color": "#ffffff", "weight":0.1 } ] }
        , { "featureType": "road.arterial", "elementType": "labels.text.stroke", "stylers": [ { "color": "#6479a9" } ] }
        , { "featureType": "transit", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "landscape.natural", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "administrative", "elementType": "geometry", "stylers": [ { "visibility": "off" }, { "color":"#5d84bf" } ] }
        , { "featureType": "administrative.province", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "administrative.neighborhood", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "administrative.land_parcel", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "landscape", "stylers": [ { "visibility": "on" }, { "color": "#b4bfda" } ] }
        , { "featureType": "landscape", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "administrative", "elementType": "labels", "stylers": [{ "visibility":"on" }] }
        , { "featureType": "water", "stylers": [ { "visibility": "on" }, { "weight": 0.7 }, { "color": "#e6ebf3" } ] }
        , { "featureType": "water", "elementType": "labels", "stylers": [ { "visibility": "off" } ] }
        , { "featureType": "administrative.country", "stylers": [ { "visibility": "on" }, { "color": "#4f4f4f" }, { "weight": 0.3 } ] }
        , { "featureType": "administrative.locality", "stylers": [ { "visibility": "on" }, { "color": "#4f4f4f" }, { "weight": 0.3 } ] }
        , { "featureType": "landscape.man_made", "stylers": [ { "color": "#92a2bc" }, { "visibility": "on" } ] }
        , { "stylers": [{ "weight": 0.5 }]}
    ];

    var sedi = {
        imgPlaceholder: '/assets/img/shared/placeholder.jpg',
        theMap: null,
        data: null,
        init: function() {


            var $this = this;
            //check for args
            sedi.locationType = $('#locationType').val();

            sedi.initializeMap();
            sedi.addPins();
            sedi.hasPushstate = !!(window.history && history.pushState);

            /*
                $(".closeDetail").on("click", function(e) {
                  e.preventDefault();
                  $(".mapDetail").slideToggle(800);

                    if (sedi.prevMarker != null) {
                        //rimette icona di default
                        idPrevPin=sedi.prevMarker.id;
                        sedi.globMarkers[idPrevPin].setIcon(CONF.assets + 'img/sedi/pin_v.png');
                    }
                });
            */
            $('.closeDetail').on('click', function(e) {
                e.preventDefault();
                $('.toggleDetail').fadeOut(800);
            });


            $("#country").change(function(val) {
                sedi.fillCity($("#country").val());
                sedi.goToCountry($("#country").val());
            });

            $("country").bind({
                "changed": function(ev, obj) {

                }
            });

            $("#city").change(function(val) {
                sedi.goToCity($("#city").val());
            });

            $("#locationType").change(function(val) {
                sedi.locationType = $(this).val();
                sedi.paintClusterer();
            });
        },
        globMarkers: null,
        prevMarker: null,
        initializeMap: function(mapOptions){
            var _mapOptions = {
                center: new google.maps.LatLng(42.0826, 11.1615),
                zoom: 1,
                minZoom: 2,
                panControl: true,
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false
            };
            mapOptions = $.extend({},_mapOptions, mapOptions);

            sedi.theMap = new google.maps.Map(document.getElementById("theMap"), mapOptions);
        },
        addPins: function(onComplete) {
            var $this = this;
            $this.globMarkers = [];
            $this.globMarkersBlue = [];
            $this.globMarkersGreen = [];
            $.getJSON(DATAMAP_URL, function(res) {
                sedi.data = res.data;
                $(sedi.data.pins).each(function(i, pin) {
                    var marker = new google.maps.Marker({
                        id: i,
                        map : sedi.theMap,
                        position: new google.maps.LatLng(pin.lat, pin.lon),
                        //icon: "/Style Library/FiatSPA2013ENV.assets + /img/maps/pin.png"
                    });
                    if (pin.locationType == 'CNH')
                    //marker.setIcon(ENV.assets + "img/sedi/pin_b.png");
                        marker.setIcon("/assets/img/shared/pin_v.png");
                    else // if(pin.locationType == 'FCA')
                        marker.setIcon("/assets/img/shared/pin_v.png");
                    marker.setValues({
                        pinID: i
                    });
                    google.maps.event.addListener(marker, 'click', function() {

                        if ($this.prevMarker != null) {
                            //rimette icona di default
                            $this.prevMarker.setIcon("/assets/img/shared/pin_v.png");
                        }
                        sedi.theMap.setCenter(marker.getPosition());
                        sedi.openDetail(marker.get("pinID"));
                        $this.prevMarker = marker;
                        //marker.setIcon(ENV.assets + 'img/sedi/pin_v.png');
                        if(sedi.hasPushstate){
                            window.history.pushState("", "", CONF.page_url + "/" + pin.slug);
                        }

                    });
                    sedi.data.pins[i].marker = marker;
                    $this.globMarkers.push(marker);

                });
                sedi.paintClusterer();
                sedi.fillStates();

                //var markerCluster = new MarkerClusterer(sedi.theMap, $this.globMarkers ,{styles: markerStyles});

                /*var markerClusterBlue = new MarkerClusterer(sedi.theMap, $this.globMarkersBlue, {
                    styles: markerStylesBlue
                });
                var markerClusterGreen = new MarkerClusterer(sedi.theMap, $this.globMarkersGreen, {
                    styles: markerStylesGreen
                });*/
                /* sedi.markerCluster = new MarkerClusterer(sedi.theMap, $this.globMarkers, {
                     styles: markerStylesGreen
                 });*/

                //check for office slug in url
                if(CONF.params !== null || CONF.params !== ''){
                    for(var i = 0 in sedi.data.pins ){
                        if(sedi.data.pins[i].slug === CONF.params ){
                            new google.maps.event.trigger( sedi.data.pins[i].marker, 'click' );
                            //scrollTo('.mapDetail');
                            google.maps.event.addListenerOnce(sedi.theMap, 'idle', function(){
                                sedi.theMap.setZoom(12);
                            });

                        }
                    }
                }
            });
        },

        paintClusterer: function(){
            sedi.markerCluster && sedi.markerCluster.clearMarkers();
            var theMarkers = [];
            for(var i in sedi.data.pins ){
                if( sedi.locationType === '' || sedi.data.pins[i].locationType == sedi.locationType || typeof sedi.data.pins[i].locationType === 'object' ){
                    theMarkers.push( sedi.data.pins[i].marker );
                    sedi.data.pins[i].marker.setVisible(true);
                } else {
                    sedi.data.pins[i].marker.setVisible(false);
                }
            }
            /*sedi.markerCluster = new MarkerClusterer(sedi.theMap, theMarkers, {
                styles: markerStylesGreen
            });*/
        },

        fillStates: function() {
            var mrk = "";
            $(sedi.data.states).each(function(i, state) {
                mrk += "<option value='" + state.sigla + "'>" + state.name + "</option>";
            })
            $("#country option").after(mrk);
        },

        fillCity: function(sid) {
            var mrk = "";
            $.ajax({
                type: "GET",
                url: citiesJSON+sid.toLocaleString(),
                dataType: "json",
                success: function(response) {
                    $.each(response.cities, function(i,city) {
                        mrk += "<option value='" + city + "'>" + city + "</option>";
                    });
                    $("#city option").not(".base").remove();
                    $("#city option.base").after(mrk);
                    sedi.goToCountry($("#city option").get(1).text);
                },
                error: function(response) {
                    console.log(response)
                }
            });
        },



        openDetail: function(id) {
            var $this = this;
            $(".gallery-maps .toggleDetail__title").empty().html(sedi.data.pins[id].name);

            $(".gallery-maps .theAddress").empty().html(sedi.data.pins[id].address);
            (sedi.data.pins[id].address == '') ? $('.address').hide(): $('.address').show();

            $(".contacts p").not(".mtitle").remove();

            var mrk = "";
            $.each(sedi.data.pins[id].contacts, function(key, value) {
                $.each(value, function(key, value) {
                    switch (key) {
                        case 'phone':
                            mrk += "<p>"
                                + "<span class='icon p'></span>"
                                + "<span><a href='phone:" + value +"'>" + value + "</a></span>"
                                + "</p>";
                            break;
                        case 'fax':
                            mrk += "<p>"
                                + "<span class='icon f'></span>"
                                + "<span><a href='phone:" + value +"'>" + value + "</a></span>"
                                + "</p>";
                            break;
                        case 'email':
                            mrk += "<p>"
                                + "<span class='icon e'></span>"
                                + "<span><a href='mailto:" + value +"'>" + value + "</a></span>"
                                + "</p>";
                            break;
                        case 'website':
                            mrk += "<p>"
                                + "<span class='icon w'></span>"
                                + "<span><a target='_blank' href='" + value +"'>" + value + "</a></span>"
                                + "</p>";
                            break;
                    }
                });
            });


            $(".contacts .mtitle").after(mrk);
            $(".pinDescription .theDescription").empty().html(sedi.data.pins[id].description);
            (sedi.data.pins[id].address == '') ? $('.pinDescription').hide(): $('.pinDescription').show();
            (mrk == '') ? $('.contacts').hide(): $('.contacts').show();
            $('.mapRight').empty();
            $('.mapRight').html("<div class='wrp-gc'><ul></ul></div>");
            var mrk = "";
            console.log(sedi.data.pins[id].galleries);
            $.each(sedi.data.pins[id].galleries, function(i, item) {
                //
                if(item.type == "photo" && item.imgUrl !== null) {
                    var thumbnail = item.thumbnail;
                    var image = item.imgUrl;
                    //var image = 'https://placehold.it/440x220.png';
                    var img = new Image(120);
                    img.onload = function () {
                        image.src = this.src;
                    };
                    img.src = image;
                    mrk += "<li><a href='"+ image +"' data-lity data-lity-desc=''><img width='120' src='" + thumbnail + "'></a></li>";
                } else if (item.type == "video") {
                    mrk += "<li><iframe width='625' height='352' src='http://www.youtube.com/embed/" + item.videoId + "?showinfo=0&controls=1&rel=0&wmode=transparent' frameborder='0' allowfullscreen></iframe></li>";
                }
            });

            if (mrk == '') {
                mrk = '<li><img src="' + $this.imgPlaceholder + '"></li>';
            }

            $(".wrp-gc ul").append(mrk);

            //$(".mapSlider, .slidesjs-previous, .slidesjs-next").remove();
            //$(".wrp-gc").append("<div class='gc mapSlider'><a class='slidesjs-previous slidesjs-navigation'></a><a class='slidesjs-next slidesjs-navigation'></a></div>");
            //$(".mapSlider").append(mrk);
            //$('.mapSlider').imagesLoaded(function () {
            //sedi.setSlider();
            //});
            if (sedi.data.pins[id].locationType == 'CNH') {
                $('.mapLeft .loghiTechPro2 ul li').html("<img src='" + CONF.assets + "img/shared/logo_blu.png'>")
            } else if (sedi.data.pins[id].locationType == 'FCA') {
                $('.mapLeft .loghiTechPro2 ul li').html("<img src='" + CONF.assets + "img/shared/logo_verde.png'>")
            } else {
                $('.mapLeft .loghiTechPro2 ul li').html("<img src='" + CONF.assets + "img/shared/loghi_new.jpg'>")
            }

            $('.toggleDetail').slideDown(400, function() {
                $('html, body').animate({
                    scrollTop: 900
                }, 800);
            });

        },

        goToCity: function(c) {
            $(sedi.data.pins).each(function(i, item) {
                if (c == item.city) {
                    sedi.theMap.setCenter(new google.maps.LatLng(item.lat, item.lon));
                    sedi.theMap.setZoom(11);
                }
            })
        },

        goToCountry: function(name) {
            var c = name;
            $(sedi.data.pins).each(function(i, item) {
                if (c == item.city) {
                    sedi.theMap.setCenter(new google.maps.LatLng(item.lat, item.lon));
                    sedi.theMap.setZoom(5);
                }
            })
        }
    }

    return {
        init: init
    };
})(jQuery);

