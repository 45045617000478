var countrySelect = (function () {
    function init() {
        initCountrySelectModal();
    }

    function initCountrySelectPopover() {
        var countryList = $(".countryList").clone().removeClass('d-none');
        var countrySelect = $(".countrySelect");
        countrySelect.popover({
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body">'+countryList.html()+'</div></div>',
            html: true,
            placement: 'bottom',
            trigger: 'focus'

        });
    }

    function initCountrySelectModal() {
        $(".countrySelect").on("click", function (e) {
            $("#countrySelect").modal('show');
            e.preventDefault();
        })
    }

    return {
        init: init
    };
})(jQuery);