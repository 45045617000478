var navbarManager = (function () {
    function init() {
        toggleNavbar();
    }

    function toggleNavbar() {
        $(".navbar-collapse").on("show.bs.collapse", function () {
           $(".navbar-toggler").addClass('open')
        });
        $(".navbar-collapse").on("hide.bs.collapse", function () {
           $(".navbar-toggler").removeClass('open')
        });
    }

    return {
        init: init
    };
})(jQuery);