var slider = (function () {
    function init() {
        initHomepageMainSlider();
    }

    function initHomepageMainSlider() {
        var slider = $('.homepageMainSlider');
        var sliderCount = slider.length;
        if (sliderCount === 1) {
            slider.owlCarousel(
                getSliderOptionBasedNumberOfElements(slider)
            );
        } else if (sliderCount > 1) {
            slider.each(function () {
                $(this).owlCarousel(
                    getSliderOptionBasedNumberOfElements(slider)
                );
            });
        }
    }

    function getSliderOptionBasedNumberOfElements(slider) {
        var countItem = $(".item", slider).length;
        var option = {
            items: 1,
            margin: 0,
            singleItem: true,
            autoHeight: true
        };

        if (countItem === 1) {
            option.loop = false;
            option.mouseDrag = false;
        } else {
            option.loop = true;
            option.autoplayTimeout = 3000;
            option.autoplayHoverPause = true;
            option.autoplay = true;
        }
        return option;
    }

    return {
        init: init
    };
})(jQuery);
