$(function () {
    formManager.init();
    slider.init();
    collapseBox.init();
    countrySelect.init();
    featureBox.init();
    officeManager.init();
    navbarManager.init();
    chartManager.init();
    news.init();
});